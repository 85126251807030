export const policies = [
    {
        title: "Privacy Policy",
        dataId: "64a6169f-be20-4f33-94b0-ea9abdccae48",
        id: "privacy"
    },
    {
        title: "Terms & Conditions",
        dataId: "9d8a95a6-ad4f-45ac-a67d-ba065ce40125",
        id: "terms"
    },
    {
        title: "Cookie Policy",
        dataId: "0dd58949-16c3-43cb-80b7-de6dde9370b7",
        id: "cookies"
    },
    
    {
        title: "Refunds & Returns",
        dataId: "1837871f-7cf9-41c8-85b5-420d395160f5",
        id: "return"
    },
    {
        title: "Disclaimer",
        dataId: "072a1108-04b4-47ee-bb81-5e8a275cb69e",
        id: "disclaimer"
    },

]
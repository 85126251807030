import React from "react";
import styles from "./styles.module.scss";

const Button = ({
  children,
  size,
  variant,
  outline,
  leftIcon,
  rightIcon,
  action,
  customStyle,
  disabled,
  props
}) => {
  let buttonStyles = [styles.stbButton];
  if (variant === "secondary") buttonStyles.push(styles.secondary);
  if (outline) buttonStyles.push(styles.outline);
  switch (size?.toLowerCase()) {
    case "s":
      buttonStyles.push(styles.small);
      break;
    case "l":
      buttonStyles.push(styles.large);
      break;
    case "m":
    default:
      buttonStyles.push(styles.medium);
  }

  buttonStyles = buttonStyles.join(" ");

  return (
    <button className={buttonStyles} onClick={action} style={customStyle} disabled={disabled} {...props}>
      {leftIcon ? <span className={styles.leftIcon}>{leftIcon}</span> : null}
      {children}
      {rightIcon ? <span className={styles.rightIcon}>{rightIcon}</span> : null}
    </button>
  );
};

export default Button;

import React, { useState } from 'react'
import styles from './styles.module.scss'

const RadioFilter = ({ title, items, setBrandFilter, selected }) => {

    return (
        <article className={styles.filter}>
            <p className={styles.filterTitle}>{title}</p>
            <ul>
                {
                    items.map((item, index) => {
                        return <li key={index} className={item.value === selected && styles.selected} onClick={() => setBrandFilter(item)}>{item.text}</li>
                    })
                }
            </ul>

        </article>
    )
}

export default RadioFilter

import { mdiClose, mdiMagnify } from '@mdi/js';
import React, { useEffect } from 'react';
import NavItem from '../NavItem';
import Icon from '@mdi/react';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm } from 'react-hook-form';
import Button from '../../Button';
import styles from './styles.module.scss';

const SearchToolbar = ({ callSearch, handleToggleSearch }) => {
    const { register, handleSubmit, setFocus } = useForm();

    const onSubmit = data => {
        callSearch(data.query);
    };

    const searchButtonStyle = {
        // borderTopLeftRadius: '0',
        // borderBottomLeftRadius: '0',
        height: "2.5rem",
        width: "2.5rem",
        //backgroundColor: '#fff',
        //color: "#1a1a1a",
        paddingLeft: "0rem",
        paddingRight: "0rem",
    };

    // React.useEffect(() => {
    //     setFocus("query");
    // }, [setFocus]);

    return (
        <nav className={styles.searchToolbar}>
            <Form onSubmit={handleSubmit(onSubmit)} className={styles.searchForm}>

                <Form.Group className={styles.formGroup}>
                    <InputGroup className={styles.inputGroup}>
                        <Form.Control className={styles.formControl} type="search"
                            placeholder="Search Products..."
                            name="query"
                            ref={register({ required: 'You must enter some text.', minLength: 3 })}
                        />
                        <InputGroup.Append className={styles.inputGroupAppend}>
                            <Button type="submit" customStyle={searchButtonStyle}>
                                <Icon path={mdiMagnify} size={1} />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </Form>
            <div className={styles.closeSearchToolbar}>
                <NavItem type="toggler" action={() => handleToggleSearch(false)} isActive={false} title="Cancel Search">
                    <Icon path={mdiClose}
                        size={1}
                    />
                </NavItem>
            </div>

        </nav>
    )
}

export default SearchToolbar

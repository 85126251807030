import { GET_CATEGORIES } from "../actions/categoryAction";

const initState = {
    categories: [],
};

const categoryReducer = (state = initState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_CATEGORIES:
            return { ...state, categories: payload.categories };
        default:
            return state;
    }
}

export default categoryReducer;
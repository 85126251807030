import React from 'react'
import styles from './styles.module.scss'

const MenuButton = ({ handler, active }) => {
    return (
        <button onClick={handler} className={[styles.button, (active && styles.active)].join(" ")}>
            <div className={styles.icon}>
                <div className={styles.bar1}></div>
                <div className={styles.bar2}></div>
                <div className={styles.bar3}></div>

            </div>

        </button>
    )
}

export default MenuButton

import React from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setProductID } from '../../redux/actions/productActions';
import styles from "./styles.module.scss"

import defaultImage from '../../assets/images/prodNoImg.png'
import Button from '../../components/Button';


const ProductItem = ({ product, setProductID }) => {


    return (
        <article className={styles.productItem}>

            <Link to={`/product/${product?.description?.friendlyUrl}`} onClick={() => setProductID(product?.id)}>
                <div className={styles.picture}>
                    <img src={product?.image?.imageUrl || defaultImage} alt={product?.title} />
                </div>
            </Link>

            <div className={styles.info}>
                <Link to={`/product/${product?.description?.friendlyUrl}`} onClick={() => setProductID(product?.id)}>
                    <header>
                        <h1>{product.description.name}</h1>
                        <p>{product.description.highlights}</p>
                        <p className={styles.price}>{product.finalPrice}</p>
                    </header>
                </Link>
                {/* <div className={styles.actions}>
                    <Button>Add to Cart</Button>
                </div> */}
            </div>
        </article>

    )
}
const mapStateToProps = state => {
    return {
        defaultStore: state.merchantData.defaultStore
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setProductID: (value) => {
            //console.log("setProductID: ", value)
            dispatch(setProductID(value));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);


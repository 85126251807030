import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { useHistory } from "react-router-dom";
import styles from './styles.module.scss'


const StoreMenu = ({ categories, onClickCategory }) => {

    //const [confirmationModalShow, setConfirmationModalShow] = useState(false);

    const history = useHistory();

    return (
        <div className={styles.menuContainer}>
            <ul className={styles.level1}>
                {
                    categories.map((item, index) => {
                        //console.log(item);
                        return (
                            item.visible &&
                            <li key={index} >
                                <header>
                                    <Link to={"/category/" + item.description.friendlyUrl} onClick={() => onClickCategory(item)}>
                                        {item.description.name}
                                    </Link>
                                    {/* {item.children?.length > 0 &&
                                                    <Icon path={mdiChevronDown} size={1} className={styles.chevron}/>
                                                } */}
                                </header>

                                {
                                    item.children && item.children.length > 0 &&
                                    <ul className={styles.level2}>
                                        {
                                            item.children.map((submenu, index) => {
                                                return (
                                                    submenu.visible && <li key={index}>
                                                        <Link to={"/category/" + submenu.description.friendlyUrl} onClick={() => onClickCategory(submenu)} >
                                                            {submenu.description.name}
                                                        </Link>
                                                    </li>)
                                            })
                                        }

                                    </ul>
                                }
                            </li>
                        )
                    })
                }
                <li>
                    <header>
                        <Link to={"/alcohol-products/perduret"}>
                            PERDURET
                        </Link>
                    </header>
                </li>
            </ul>
        </div>
    )
}

export default StoreMenu

import React from 'react'
import styles from './styles.module.scss'
import AppFooterBrand from '../AppFooterBrand'
import SocialIcon from '../SocialIcon'

import { mdiFacebook } from '@mdi/js'
import { mdiInstagram } from '@mdi/js'
import { mdiTwitter } from '@mdi/js'
import { tiktok } from '../../assets/icons/tiktok'
import { Link, useLocation } from 'react-router-dom'
import { policies } from '../../data/policies'


const AppFooter = () => {

  const location = useLocation();



  return (
    <footer className={styles.footer}>
      <div className={styles.layout}>
        <section className={styles.mark}>
          <AppFooterBrand className={styles.appFooterBrand}></AppFooterBrand>
          <ul className={styles.social}>

            <li>
              <SocialIcon path={mdiFacebook}
                link="https://facebook.com/profile.php?id=100067981844550"
                title="Follow us on Facebook" />
            </li>
            <li>
              <SocialIcon path={mdiInstagram}
                link="https://instagram.com/savethebees.co"
                title="Follow us on instagram.com/savethebees.co" />
            </li>
            <li>
              <SocialIcon path={mdiTwitter}
                link="https://twitter.com/SaveTheBeesFDN"
                title="Follow us on twitter.com/savethebeesc" />
            </li>
            <li>
              <SocialIcon path={tiktok}
                link="https://www.tiktok.com/@savethebees.com"
                title="Follow us on TikTok" />
            </li>
          </ul>
        </section>
        {/* <section className={styles.aboutUs}>
          <h5>About Us</h5>
          <ul>
            <li>Company Information</li>
            <li>Our Team</li>
            <li>Our Vision</li>
            <li>Team of Experts</li>
            <li>Press Releases</li>
          </ul>
        </section> */}

        <section className={styles.legalPolicies}>
          <h5>Legal</h5>
          <ul>

            {policies.map(
              (policy, index) =>
                <li key={policy.id}>
                  <Link
                    to={{
                      pathname: "/legal-policies",
                      search: `?policy=${policy.id}`
                    }}
                  >
                    {policy.title}
                  </Link>
                </li>
            )}

          </ul>
        </section>
        <section className={styles.contact}>
          <h5>Contact Us</h5>
          <article>
            <ul>
              <li>Email:</li>
              <li><a href="mailto:bees@savethebees.com">bees@savethebees.com</a></li>
              <li>Call or Text:</li>
              <li><a href="tel:+19167083488">+1 (916) 708-3488</a></li>
            </ul>
          </article>


        </section>

      </div>

    </footer>
  )
}

export default AppFooter

import React from 'react'
import styles from './styles.module.scss'

import logo from '../../assets/images/logo-iso.png'

const AppFooterBrand = () => {
    return (
        <div className={styles.FooterBrand}>
            <img src={logo} alt="Save The Bees Logo" className={styles.logo} />
            <h1>Save The Bees</h1>
        </div>


    )
}

export default AppFooterBrand

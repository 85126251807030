import PropTypes from "prop-types";
import React, { useEffect } from 'react'
import { getShippingCountry } from "../../redux/actions/userAction";
import { multilanguage } from "redux-multilanguage";
import { setMerchant } from "../../redux/actions/storeAction";
import { getCategories } from "../../redux/actions/categoryAction";
import AppHeader from "../../components/AppHeader";
import AppFooter from "../../components/AppFooter";
import styles from './styles.module.scss'
import { connect } from "react-redux";

const MainLayout = ({
    setMerchant,
    merchant,
    children,
    countries,
    getShippingCountry,
    categories,
    getCategories
}) => {

    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);
    useEffect(() => {
        if (!countries.length) {
            getShippingCountry(multilanguage.currentLanguageCode);
        }

        if (!merchant) {
            setMerchant();
        }

        if (!categories.length) {
            getCategories();
        }

    }, [countries, merchant, getShippingCountry, setMerchant]);

    return (

        <div className={[styles.main, (mobileMenuOpen && styles.mobileMenuOpen)].join(" ")}>
            <AppHeader 
                mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={(data) => setMobileMenuOpen(data)}
            />
            <section className={styles.content}>
                {children}
            </section>
            <AppFooter />
        </div>

    )
}

MainLayout.propTypes = {
    children: PropTypes.any,
    headerContainerClass: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    headerTop: PropTypes.string
};

const mapStateToProps = state => {
    return {
        countries: state.userData.shipCountry,
        merchant: state.merchantData.merchant,
        categories: state.categories.categories,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getShippingCountry: (value) => {
            dispatch(getShippingCountry(value));
        },
        setMerchant: () => {
            dispatch(setMerchant());
        },
        getCategories: () => { 
            dispatch(getCategories()) 
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(MainLayout));
import GoBack from '../../components/GoBack';
import MainLayout from '../../layouts/MainLayout';
import React, { Fragment, useEffect, useState } from 'react';
import { MetaTags } from 'react-meta-tags';
import { connect } from 'react-redux';
import { multilanguage } from 'redux-multilanguage';
import { useLocation, useHistory } from "react-router-dom";
import styles from "./styles.module.scss";
import ProductItem from '../../components/ProductItem';
import WebService from "../../util/webService";
import constant from "../../util/constant";
import { isCheckValueAndSetParams } from "../../util/helper";
import RadioFilter from '../../components/RadioFilter';
import RangeFilter from '../../components/RangeFilter';
import Tag from '../../components/Tag';
import Button from '../../components/Button';
import { setLoader } from "../../redux/actions/loaderActions";
import { setCategoryID } from "../../redux/actions/productActions";
import EmptyResults from '../../components/EmptyResults';


import Icon from '@mdi/react';
import { mdiChevronDown } from '@mdi/js';

const SearchResults = ({ defaultStore, merchant, setLoader, categories, setCategoryID }) => {

    const history = useHistory();
    const location = useLocation();
    const [query, setQuery] = useState(new URLSearchParams(location.search).get('q'));
    const [pageLimit, setPageLimit] = useState(50);
    const currentLanguageCode = "en";
    const [productSearchResults, setProductSearchResults] = useState({
        number: 0,
        products: [],
        recordsFiltered: 0,
        recordsTotal: 0,
        totalPages: 0
    });
    const [loading, setLoading] = useState(false);
    const [showFilters, setShowFilters] = useState(false);
    const [brandFilter, setBrandFilter] = useState(null);
    const [priceFilter, setPriceFilter] = useState(null);
    const [manufacturers, setManufactures] = useState([]);


    const getSearchResults = async () => {
        setLoader(true);
        setLoading(true);
        let action = `${constant.ACTION.SEARCH_PRODUCTS
            }?${isCheckValueAndSetParams(
                "&store=",
                defaultStore
            )}${isCheckValueAndSetParams(
                "&lang=",
                currentLanguageCode
            )}${isCheckValueAndSetParams(
                "&name=",
                query
            )}${isCheckValueAndSetParams(
                "&count=",
                pageLimit
            )}${isCheckValueAndSetParams(
                "&page=",
                0
            )}
            ${isCheckValueAndSetParams(
                "&manufacturer=",
                brandFilter?.value
            )}
            ${isCheckValueAndSetParams(
                "&priceFrom=",
                priceFilter?.min
            )}
            ${isCheckValueAndSetParams(
                "&priceUntil=",
                priceFilter?.max
            )}`;
        let response = await WebService.get(action);
        setProductSearchResults(response);
        setLoader(false);
        setLoading(false);
    };

    const getManufacturers = async () => {
        setLoader(true);
        let action = `${constant.ACTION.MANUFACTURERS
            }?${isCheckValueAndSetParams(
                "&store=",
                defaultStore
            )}${isCheckValueAndSetParams(
                "&lang=",
                currentLanguageCode
            )}`;
        let response = await WebService.get(action);
        setManufactures(response.manufacturers.map(manufacturer => ({ value: manufacturer.id, text: manufacturer.description?.name })))
        setLoader(false);
    };

    //Change this code: category should be visible
    const viewAllProducts = () => {
        const category = categories.sort((a, b) => (a.sortOrder > b.sortOrder) ? 1 : -1)[0];
        const { id, description } = category;
        setCategoryID(id);
        history.push(`/category/${description.friendlyUrl}`);
    };

    useEffect(() => {
        getManufacturers();
    }, []);

    useEffect(() => {
        getSearchResults();
    }, [query, brandFilter, priceFilter]);

    useEffect(() => {
        setQuery(new URLSearchParams(location.search).get('q'));
    }, [location])
    const producstFiltered = productSearchResults?.products.filter(prod => prod.productShipeable && prod.available && prod.visible && prod.categories[0]?.visible);
    return (
        <Fragment>
            <MetaTags>
                <title>Search | {merchant.name}</title>
                <meta
                    name="description"
                    content="Save The Bees"
                />
            </MetaTags>
            <MainLayout>

                <section className={styles.section}>


                    <div className={styles.layout}>
                        <nav className={styles.nav}>
                            <GoBack />
                        </nav>
                        <aside>
                            <header>
                                <h1>{query}</h1>
                                <p>{producstFiltered.length} products</p>
                            </header>
                            <section className={[styles.filters, (!showFilters? styles.closed : "")].join(" ")}>
                                <header onClick={() => setShowFilters(!showFilters)}>
                                    <h2>Filters</h2>
                                    <Icon path={mdiChevronDown} size={1} />
                                </header>
                                <article>
                                    <div className={styles.appliedFilters}>
                                        {brandFilter && <Tag onClick={() => setBrandFilter(null)}>{brandFilter.text}</Tag>}
                                        {priceFilter && <Tag onClick={() => setPriceFilter(null)}>${priceFilter.min} - ${priceFilter.max}</Tag>}
                                    </div>
                                    <RadioFilter title="Brand" setBrandFilter={setBrandFilter} items={manufacturers} selected={brandFilter?.value} />
                                    <RangeFilter title="Price" setPriceFilter={setPriceFilter} />
                                </article>

                            </section>

                        </aside>
                        {
                            producstFiltered.length > 0 &&
                            <section className={styles.results}>
                                <ul className={styles.productsList}>
                                    {
                                        producstFiltered.map((product, index) => {
                                            return <li key={index}><ProductItem product={product} /></li>
                                        })
                                    }
                                </ul>
                            </section>
                        }
                        {
                            !loading && producstFiltered.length === 0 && <EmptyResults action={()=>viewAllProducts()}/>
                            
                        }



                    </div>
                </section>
            </MainLayout >
        </Fragment >
    )
}

const mapStateToProps = state => {
    return {
        defaultStore: state.merchantData.defaultStore,
        merchant: state.merchantData.merchant,
        categories: state.categories.categories,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setLoader: (value) => {
            dispatch(setLoader(value));
        },
        setCategoryID: (id) => { dispatch(setCategoryID(id)) },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(SearchResults));

import React from 'react'
import { Link } from "react-router-dom"
import styles from './styles.module.scss'

import logo from '../../assets/images/beeLogo.png'

const HeaderBrand = () => {
    return (
        <Link to="/" className={styles.HeaderBrand}>
            <img src={logo} alt="Save The Bees Logo" className={styles.logo} />
            <h1 className='bebas-neue'>Save The Bees</h1>
        </Link>
    )
}

export default HeaderBrand

import React from 'react'
import { connect } from "react-redux";
import Icon from '@mdi/react'
import { mdiShoppingOutline } from '@mdi/js'
import NavItem from '../NavItem';
import styles from './styles.module.scss'

const CartLink = ({ cartCount }) => {
    return (
        <NavItem to="/cart" type="link" title="View Cart">
            <Icon path={mdiShoppingOutline}
                size={1}
            />
            {cartCount > 0 &&
                <div className={styles.badge}>
                    {cartCount}
                </div>
            }
        </NavItem>
    )
}

const mapStateToProps = state => ({
    cartCount: state.cartData.cartCount,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CartLink);


// import PropTypes from "prop-types";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { connect } from "react-redux";
// import { multilanguage, loadLanguages } from "redux-multilanguage";
import { multilanguage } from "redux-multilanguage";
import styles from "./styles.module.scss";
import buttonStyles from "../Button/styles.module.scss";	

const Cookie = ({ strings }) => {
    return (
        <CookieConsent
            location="bottom"
            buttonText={strings["Accept"]}
            disableStyles={true}
            buttonClasses={buttonStyles.stbButton}
            containerClasses={styles.cookieConsent}
            contentClasses="text-capitalize"

        >
            {strings["Cookie Consent"]}
        </CookieConsent>
    );
};

const mapStateToProps = (state) => {
    return {
        currentLanguageCode: state.multilanguage.currentLanguageCode,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(multilanguage(Cookie));

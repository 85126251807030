import React from 'react'
import Icon from '@mdi/react'
import styles from './styles.module.scss'

const SocialIcon = (props) => {
    return (
        <a href={props.link} target="_blank" className={styles.socialIcon} rel="noreferrer">
            <Icon path={props.path}
                title={props.title}
                size={1}

            />
        </a>
    )
}

export default SocialIcon

import { mdiCheck, mdiChevronRight } from '@mdi/js';
import Icon from '@mdi/react';
import React from 'react';
import { Form, InputGroup, FormControl } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import styles from './styles.module.scss';

const RangeFilter = ({ title, setPriceFilter }) => {
   // const { register, handleSubmit, errors } = useForm();
   const [min, setMin] = React.useState('');
   const [max, setMax] = React.useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const data = {
            min: min,
            max: max
        }
        //console.log(data);
        setPriceFilter(data);
    };

    return (
        <article className={styles.filter}>
            <p className={styles.filterTitle}>{title}</p>

            <Form inline onSubmit={handleSubmit} className={styles.rangeForm}>
                <Form.Control 
                    size="sm" 
                    className={[styles.formControl, (min > max || max && !min) && styles.formControlWithError].join(' ')} 
                    type="number"
                    name="min" 
                    placeholder="min" 
                    value={min}
                    max={max}
                    onChange={(e) => setMin(e.target.value)}
                    //ref={register({ required: 'Min is required', maxLength: 255 })} 
                    //isInvalid={errors.min?.type === 'required'} 
                />
                <p className={styles.separator}>-</p>
                <Form.Control 
                    size="sm" 
                    className={[styles.formControl, (min > max || min && !max) && styles.formControlWithError].join(' ')}
                    type="number" 
                    placeholder="max"
                    name="max"
                    value={max}
                    onChange={(e) => setMax(e.target.value)}
                    min={min}
                    step="any"
                    //isInvalid={errors.max?.type === 'required'}
                    
                    //ref={register({ required: 'Max is required', maxLength: 255 })} 
                />
                <Form.Control size="sm" as="div" className={styles.submitControl} custom>
                    <Button variant="secondary" size="s" type="submit" className={styles.submitButton} disabled={!(min && max && min<=max)}>
                        <Icon path={mdiChevronRight} className={styles.submitIcon}/>
                    </Button>
                </Form.Control>

            </Form>

        </article>
    )
}

export default RangeFilter

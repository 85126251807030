import { mdiClose } from '@mdi/js'
import Icon from '@mdi/react'
import React from 'react'
import styles from './styles.module.scss'

const Tag = ({children, onClick}) => {
  return (
    <div className={styles.stbTag}>
      {children} <Icon path={mdiClose} className={styles.stbTagIcon} onClick={onClick} />
    </div>
  )
}

export default Tag
